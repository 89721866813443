import {Suspense, useEffect} from 'react'
import {Routes, Route, useLocation} from 'react-router-dom'
import ReactGA from 'react-ga4'
import Login2FA from './components/Screen/Login2FA'
import {ROUTE} from './config/route'
import {Dashboard} from './components/Screen/Dashboard/Dashboard'
import DossierDetail from './components/Screen/Dossier/DossierDetail'
import Landing from './components/Screen/Dossier/Landing/Landing'
import Digid from './components/Screen/Dossier/Digid/Digid'
import DigidStepOne from './components/Screen/Dossier/Digid/DigidStepOne'
import DigidStepTwo from './components/Screen/Dossier/Digid/DigidStepTwo'
import DigidStepThree from './components/Screen/Dossier/Digid/DigidStepThree'
import Permission from './components/Screen/Dossier/Permission/Permission'
import Physician from './components/Screen/Dossier/Physician/Physician'
import MedicationScreen from './components/Screen/Medication/MedicationScreen'
import Ggz from './components/Screen/Dossier/Ggz/Ggz'
import Appointment from './components/Screen/Appointment/Appointment'
import Welcome from './components/Screen/Welcome/Welcome'
import DocumentScreen from './components/Screen/Document/DocumentScreen'
import HospitalScreen from './components/Screen/Dossier/Hospital/HospitalScreen'

function App(): JSX.Element {
    const location = useLocation()

    ReactGA.initialize([
        {
            trackingId: 'G-HS2JK7WFPC',
        },
    ])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
    }, [location])

    return (
        <Suspense fallback='Een ogenblik geduld alstublieft'>
            <Routes>
                <Route path={ROUTE.LOGIN}>
                    <Route index element={<Welcome/>}/>
                    <Route path={ROUTE.LOGIN2FA} element={<Login2FA/>}/>
                    <Route path={ROUTE.DOSSIER_DETAIL} element={<DossierDetail/>}/>
                    <Route path={ROUTE.DASHBOARD} element={<Dashboard/>}/>
                    <Route path={ROUTE.DOSSIER_LANDING} element={<Landing/>}/>
                    <Route path={ROUTE.DOSSIER_DIGID} element={<Digid/>}/>
                    <Route path={ROUTE.DOSSIER_DIGIDSTEPONE} element={<DigidStepOne/>}/>
                    <Route path={ROUTE.DOSSIER_DIGIDSTEPTWO} element={<DigidStepTwo/>}/>
                    <Route path={ROUTE.DOSSIER_DIGIDSTEPTHREE} element={<DigidStepThree/>}/>
                    <Route path={ROUTE.DOSSIER_PERMISSION} element={<Permission/>}/>
                    <Route path={ROUTE.DOSSIER_PHYSICIAN} element={<Physician/>}/>
                    <Route path={ROUTE.DOSSIER_GGZ} element={<Ggz/>}/>
                    <Route path={ROUTE.DOSSIER_HOSPITAL} element={<HospitalScreen/>}/>
                    <Route path={ROUTE.DOSSIER_MEDICATION} element={<MedicationScreen/>}/>
                    <Route path={ROUTE.APPOINTMENT} element={<Appointment/>}/>
                    <Route path={ROUTE.DOCUMENTS} element={<DocumentScreen/>}/>
                </Route>
            </Routes>
        </Suspense>
    )
}

export default App
